import { Injectable } from "@angular/core";
import type { StateContext } from "@ngxs/store";
import { Action, State } from "@ngxs/store";

import { ClearPaginationAction, SetPaginationAction } from "./pagination.actions";
import type { PaginationStateModel } from "./pagination.state.model";
import { PAGINATION_FEATURE_KEY } from "./pagination.state.model";

@Injectable({ providedIn: "root" })
@State<PaginationStateModel>({
    name: PAGINATION_FEATURE_KEY,
    defaults: {
        currentPage: 1,
        pageSize: 10,
        hasMorePages: false,
    },
})
export class PaginationState {
    @Action(SetPaginationAction)
    public setPagination(ctx: StateContext<PaginationStateModel>, action: SetPaginationAction) {
        ctx.patchState({
            ...action.pagination,
        });
    }

    @Action(ClearPaginationAction)
    public clearPagination(ctx: StateContext<PaginationStateModel>) {
        ctx.patchState({
            currentPage: 1,
            pageSize: 10,
            hasMorePages: false,
        });
    }
}
