import { Injectable } from "@angular/core";
import { Action, State, StateContext } from "@ngxs/store";
import { DictionariesStateModel, DICTIONARIES_FEATURE_KEY, IDictionaries } from "./dictionaries.state.model";
import { ClubsByStateTreeItem, GetLookupsResponse, LookupService } from "../../api";
import { NotificationsService } from "@app/core/services/notifications";
import { HttpErrorResponse } from "@angular/common/http";
import { map, tap, catchError, EMPTY } from "rxjs";
import { LoadDictionariesAction, LoadHomeLocationsAction, LoadSelectedDictionariesAction } from "./dictionaries.actions";
import { mapToDictionariesFromLookups } from "./utils/mappers/map-to-dictionaries-from-lookups";

@Injectable({ providedIn: "root" })
@State<DictionariesStateModel>({
    name: DICTIONARIES_FEATURE_KEY,
    defaults: {
        entities: {
            MemberStatuses: null,
            MemberStates: null,
            HomeLocations: null,
            Processes: null,
        },
        isLoading: false,
        error: null,
    },
})
export class DictionariesState {
    constructor(private lookupService: LookupService, private notificationsService: NotificationsService) {}

    @Action(LoadDictionariesAction)
    public loadDictionaries(ctx: StateContext<DictionariesStateModel>) {
        ctx.dispatch(new LoadSelectedDictionariesAction(["MemberStates", "MemberStatuses", "Processes"]));
    }

    @Action(LoadHomeLocationsAction)
    public loadHomeLocations(ctx: StateContext<DictionariesStateModel>) {
        ctx.patchState({
            isLoading: true,
            error: null,
        });

        return this.lookupService.lookupClubsbystateGet().pipe(
            tap((response: Array<ClubsByStateTreeItem>) => {
                ctx.setState({
                    entities: {
                        ...ctx.getState().entities,
                        HomeLocations: response.map((stateItem) => {
                            return {
                                label: stateItem.stateName || "",
                                value: stateItem.stateName || "",
                                items: (stateItem.clubs || []).map((club) => {
                                    return {
                                        label: club.clubName || "",
                                        value: club.clubId || "",
                                    };
                                }),
                            };
                        }),
                    },
                    isLoading: false,
                    error: null,
                });
            }),
            catchError((error: HttpErrorResponse) => {
                ctx.patchState({
                    isLoading: false,
                    error: error.statusText,
                });
                this.notificationsService.error("An unexpected error occurred while fetching home locations.");
                return EMPTY;
            }),
        );
    }

    @Action(LoadSelectedDictionariesAction)
    public loadSelectedDictionaries(ctx: StateContext<DictionariesStateModel>, action: LoadSelectedDictionariesAction) {
        ctx.patchState({
            isLoading: true,
            error: null,
        });

        const lookupsNames = action.dictionaries.map((lookupName: string) => lookupName.charAt(0).toUpperCase() + lookupName.slice(1));
        return this.lookupService.lookupGet({ lookupsNames }).pipe(
            map((response: GetLookupsResponse) => mapToDictionariesFromLookups(response, action.dictionaries)),
            tap((entities: Partial<IDictionaries>) => {
                ctx.setState({
                    entities: {
                        ...ctx.getState().entities,
                        ...entities,
                    },
                    isLoading: false,
                    error: null,
                });
            }),
            catchError((error: HttpErrorResponse) => {
                ctx.patchState({
                    isLoading: false,
                    error: error.statusText,
                });
                this.notificationsService.error("An unexpected error occurred while re-fetching available dictionaries.");
                return EMPTY;
            }),
        );
    }
}
