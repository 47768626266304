import { Injectable } from "@angular/core";
import { ISelectItem, ISelectItemList, ISelectItemWithId } from "@app/shared/models/form";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { LoadDictionariesAction, LoadSelectedDictionariesAction } from "./dictionaries.actions";
import { DictionariesSelectors } from "./dictionaries.selectors";
import { IDictionaries } from "./dictionaries.state.model";
import { TreeNode } from "primeng/api";

@Injectable({ providedIn: "root" })
export class DictionariesFacade {
    @Select(DictionariesSelectors.isLoading) public readonly dictionariesIsLoading$!: Observable<boolean | null>;

    @Select(DictionariesSelectors.memberStatusesDictionary) public readonly memberStatusesDictionary$!: Observable<ISelectItem[] | null>;

    @Select(DictionariesSelectors.membersAddressesStatesDictionary) public readonly membersAddressesStatesDictionary$!: Observable<ISelectItemWithId[] | null>;

    @Select(DictionariesSelectors.homeLocationsDictionary) public readonly homeLocationsDictionary$!: Observable<ISelectItemList[] | null>;

    @Select(DictionariesSelectors.processesDictionary) public readonly processesDictionary$!: Observable<ISelectItem[] | null>;

    constructor(private store: Store) {}

    public loadDictionaries(): void {
        this.store.dispatch(new LoadDictionariesAction());
    }

    public loadSelectedDictionaries(dictionaries: (keyof IDictionaries)[]): void {
        this.store.dispatch(new LoadSelectedDictionariesAction(dictionaries));
    }
}
