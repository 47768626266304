import { states } from "@app/shared/consts";
import { ISelectItemWithId } from "@app/shared/models/form";
import { IDictionary } from "../../../dictionaries.state.model";
import { map } from "lodash";

export const mapToStateNameFromStateShortcut = (states: IDictionary): ISelectItemWithId[] => {
    let mappedStates: ISelectItemWithId[] = [];
    let nonStatesValues: ISelectItemWithId[] = [];

    states.forEach((state) => {
        if (state.value && state.label) {
            const mappedState = mapToStateNameLabelFromStateShortcut(state.value);
            if (mappedState !== "" && mappedState !== state.value) {
                mappedStates.push({ id: state.value, label: mappedState, value: state.value });
            } else if (mappedState === state.value) {
                nonStatesValues.push({ id: state.value, label: state.label, value: state.value });
            }
        }
    });

    mappedStates = [...mappedStates, ...nonStatesValues];

    return mappedStates;
};

export const mapToStateNameLabelFromStateShortcut = (stateShortcut: string): string => {
    return states.find((state) => state.id === stateShortcut)?.label || stateShortcut;
};
