import { IDictionaries } from "./dictionaries.state.model";

enum DictionariesActionTypes {
  LoadDictionaries = "[Dictionaries] Load dictionaries",
  LoadSelectedDictionaries = "[Dictionaries] Load selected dictionaries",
  LoadHomeLocations = "[Dictionaries] Load home locations",
}

export class LoadDictionariesAction {
  static readonly type = DictionariesActionTypes.LoadDictionaries;
}

export class LoadHomeLocationsAction {
  static readonly type = DictionariesActionTypes.LoadHomeLocations;
}

export class LoadSelectedDictionariesAction {
  static readonly type = DictionariesActionTypes.LoadSelectedDictionaries;

  constructor(public readonly dictionaries: (keyof IDictionaries)[]) {}
}
