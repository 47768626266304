import type { PaginationStateModel } from "./pagination.state.model";

enum PaginationActionTypes {
    SetPagination = "[Pagination] Set pagination",
    ClearPagination = "[Pagination] Clear pagination",
}

export class SetPaginationAction {
    static readonly type = PaginationActionTypes.SetPagination;

    constructor(public pagination: PaginationStateModel) {}
}

export class ClearPaginationAction {
    static readonly type = PaginationActionTypes.ClearPagination;
}
