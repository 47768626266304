// eslint-disable-next-line
import { Store, Select } from "@ngxs/store";
import { Injectable } from "@angular/core";
import type { Observable } from "rxjs";

import { PaginationSelectors } from "./pagination.selectors";
import { ClearPaginationAction, SetPaginationAction } from "./pagination.actions";
import type { PaginationStateModel } from "./pagination.state.model";

@Injectable({ providedIn: "root" })
export class PaginationFacade {
    @Select(PaginationSelectors.currentPage) public readonly currentPage$!: Observable<number | null>;

    @Select(PaginationSelectors.pageSize) public readonly pageSize$!: Observable<number | null>;

    @Select(PaginationSelectors.hasMorePages) public readonly hasMorePages$!: Observable<boolean | null>;

    constructor(private store: Store) {}

    public setPagination(pagination: PaginationStateModel): void {
        this.store.dispatch(new SetPaginationAction(pagination));
    }

    public clearPagination(): void {
        this.store.dispatch(new ClearPaginationAction());
    }
}
