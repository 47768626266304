import { Selector } from "@ngxs/store";

import { PaginationState } from "./pagination.state";
import type { PaginationStateModel } from "./pagination.state.model";

export class PaginationSelectors {
    @Selector([PaginationState])
    static currentPage(state: PaginationStateModel): number {
        return state.currentPage;
    }

    @Selector([PaginationState])
    static pageSize(state: PaginationStateModel): number {
        return state.pageSize;
    }

    @Selector([PaginationState])
    static hasMorePages(state: PaginationStateModel): boolean {
        return state.hasMorePages;
    }
}
