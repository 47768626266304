import { ISelectItemList, ISelectItemWithId } from "@app/shared/models/form";

export const DICTIONARIES_FEATURE_KEY = "dictionaries";

export type IDictionary = ISelectItemWithId[];

export interface IDictionaries {
    MemberStatuses: IDictionary | null;
    MemberStates: IDictionary | null;
    HomeLocations: ISelectItemList[] | null;
    Processes: IDictionary | null;
}

export interface DictionariesStateModel {
    entities: IDictionaries;
    isLoading: boolean;
    error: string | null;
}
