import { Selector } from "@ngxs/store";
import { DictionariesStateModel, IDictionary } from "./dictionaries.state.model";
import { DictionariesState } from "./dictionaries.state";
import { ISelectItemList } from "@app/shared/models/form";
import { mapToStateNameFromStateShortcut } from "./utils/mappers/map-to-state-name-from-state-shorcut/map-to-state-name-from-state-shortcut";

export class DictionariesSelectors {
    @Selector([DictionariesState])
    public static isLoading(state: DictionariesStateModel): boolean {
        return state.isLoading;
    }

    @Selector([DictionariesState])
    public static membersAddressesStatesDictionary(state: DictionariesStateModel): IDictionary | null {
        if (!state.entities.MemberStates) {
            return null;
        }
        return mapToStateNameFromStateShortcut(state.entities.MemberStates);
    }

    @Selector([DictionariesState])
    public static memberStatusesDictionary(state: DictionariesStateModel): IDictionary | null {
        return state.entities.MemberStatuses;
    }

    @Selector([DictionariesState])
    public static processesDictionary(state: DictionariesStateModel): IDictionary | null {
        return state.entities.Processes;
    }

    @Selector([DictionariesState])
    public static homeLocationsDictionary(state: DictionariesStateModel): ISelectItemList[] | null {
      return state.entities.HomeLocations;
    }
}
