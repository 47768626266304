import type { PaginationStateModel } from "@app/core/store/pagination";

export const getPaginationFromListResponse = (response: any): PaginationStateModel => {
    if (!response) {
        throw new Error("Invalid argument passed to getPaginationFromListResponse function! Make sure the passed object contains required properties.");
    }

    return {
        currentPage: response?.currentPage || 0,
        pageSize: response?.pageSize || 0,
        hasMorePages: response?.hasMorePages || false,
    };
};
