import type { GetLookupsResponse, Lookup, LookupValue } from "@app/core/api";
import type { IDictionaries } from "@app/core/store/dictionaries/dictionaries.state.model";

export const mapToDictionariesFromLookups = (response: GetLookupsResponse, selectedDictionaries: (keyof IDictionaries)[]): Partial<IDictionaries> => {
    const memberStatusesLookupIndex = response.lookups!.findIndex((lookup: Lookup) => lookup.name === "MemberStatuses");
    const memberStatesLookupIndex = response.lookups!.findIndex((lookup: Lookup) => lookup.name === "MemberStates");
    const processesLookupIndex = response.lookups!.findIndex((lookup: Lookup) => lookup.name === "Processes");
    // @ts-ignore
    const mapToOptionsFromLookupValues = (lookupValue: LookupValue): ISelectItem => ({ value: lookupValue.value, label: lookupValue.name });

    let dictionaries: Partial<IDictionaries> = {
        MemberStatuses: [],
        MemberStates: [],
    };

    if (memberStatusesLookupIndex !== -1) {
        dictionaries = {
            ...dictionaries,
            // @ts-ignore
            MemberStatuses: response.lookups[memberStatusesLookupIndex].values.map(mapToOptionsFromLookupValues),
        };
    }

    if (memberStatesLookupIndex !== -1) {
        dictionaries = {
            ...dictionaries,
            // @ts-ignore
            MemberStates: response.lookups[memberStatesLookupIndex].values.map(mapToOptionsFromLookupValues),
        };
    }

    if (processesLookupIndex !== -1) {
        dictionaries = {
            ...dictionaries,
            // @ts-ignore
            Processes: response.lookups[processesLookupIndex].values.map(mapToOptionsFromLookupValues),
        };
    }

    if (selectedDictionaries) {
        Object.keys(dictionaries).forEach((dictionaryKey: string) => {
            if (!selectedDictionaries.includes(dictionaryKey as keyof IDictionaries)) {
                delete dictionaries[dictionaryKey as keyof IDictionaries];
            }
        });
    }

    return dictionaries;
};
