import { ISelectItemWithId } from "../models/form";

export const states = [
  { id: 'AL', label: 'Alabama', value: 'Alabama' } as ISelectItemWithId,
  { id: 'AK', label: 'Alaska', value: 'Alaska' } as ISelectItemWithId,
  { id: 'AZ', label: 'Arizona', value: 'Arizona' } as ISelectItemWithId,
  { id: 'AR', label: 'Arkansas', value: 'Arkansas' } as ISelectItemWithId,
  { id: 'CA', label: 'California', value: 'California' } as ISelectItemWithId,
  { id: 'CO', label: 'Colorado', value: 'Colorado' } as ISelectItemWithId,
  { id: 'CT', label: 'Connecticut', value: 'Connecticut' } as ISelectItemWithId,
  { id: "DC", label: "District of Columbia", value: "District of Columbia" } as ISelectItemWithId,
  { id: 'DE', label: 'Delaware', value: 'Delaware' } as ISelectItemWithId,
  { id: 'FL', label: 'Florida', value: 'Florida' } as ISelectItemWithId,
  { id: 'GA', label: 'Georgia', value: 'Georgia' } as ISelectItemWithId,
  { id: 'HI', label: 'Hawaii', value: 'Hawaii' } as ISelectItemWithId,
  { id: 'ID', label: 'Idaho', value: 'Idaho' } as ISelectItemWithId,
  { id: 'IL', label: 'Illinois', value: 'Illinois' } as ISelectItemWithId,
  { id: 'IN', label: 'Indiana', value: 'Indiana' } as ISelectItemWithId,
  { id: 'IA', label: 'Iowa', value: 'Iowa' } as ISelectItemWithId,
  { id: 'KS', label: 'Kansas', value: 'Kansas' } as ISelectItemWithId,
  { id: 'KY', label: 'Kentucky', value: 'Kentucky' } as ISelectItemWithId,
  { id: 'LA', label: 'Louisiana', value: 'Louisiana' } as ISelectItemWithId,
  { id: 'ME', label: 'Maine', value: 'Maine' } as ISelectItemWithId,
  { id: 'MD', label: 'Maryland', value: 'Maryland' } as ISelectItemWithId,
  { id: 'MA', label: 'Massachusetts', value: 'Massachusetts' } as ISelectItemWithId,
  { id: 'MI', label: 'Michigan', value: 'Michigan' } as ISelectItemWithId,
  { id: 'MN', label: 'Minnesota', value: 'Minnesota' } as ISelectItemWithId,
  { id: 'MS', label: 'Mississippi', value: 'Mississippi' } as ISelectItemWithId,
  { id: 'MO', label: 'Missouri', value: 'Missouri' } as ISelectItemWithId,
  { id: 'MT', label: 'Montana', value: 'Montana' } as ISelectItemWithId,
  { id: 'NE', label: 'Nebraska', value: 'Nebraska' } as ISelectItemWithId,
  { id: 'NV', label: 'Nevada', value: 'Nevada' } as ISelectItemWithId,
  { id: 'NH', label: 'New Hampshire', value: 'New Hampshire' } as ISelectItemWithId,
  { id: 'NJ', label: 'New Jersey', value: 'New Jersey' } as ISelectItemWithId,
  { id: 'NM', label: 'New Mexico', value: 'New Mexico' } as ISelectItemWithId,
  { id: 'NY', label: 'New York', value: 'New York' } as ISelectItemWithId,
  { id: 'NC', label: 'North Carolina', value: 'North Carolina' } as ISelectItemWithId,
  { id: 'ND', label: 'North Dakota', value: 'North Dakota' } as ISelectItemWithId,
  { id: 'OH', label: 'Ohio', value: 'Ohio' } as ISelectItemWithId,
  { id: 'OK', label: 'Oklahoma', value: 'Oklahoma' } as ISelectItemWithId,
  { id: 'OR', label: 'Oregon', value: 'Oregon' } as ISelectItemWithId,
  { id: 'PA', label: 'Pennsylvania', value: 'Pennsylvania' } as ISelectItemWithId,
  { id: 'RI', label: 'Rhode Island', value: 'Rhode Island' } as ISelectItemWithId,
  { id: 'SC', label: 'South Carolina', value: 'South Carolina' } as ISelectItemWithId,
  { id: 'SD', label: 'South Dakota', value: 'South Dakota' } as ISelectItemWithId,
  { id: 'TN', label: 'Tennessee', value: 'Tennessee' } as ISelectItemWithId,
  { id: 'TX', label: 'Texas', value: 'Texas' } as ISelectItemWithId,
  { id: 'UT', label: 'Utah', value: 'Utah' } as ISelectItemWithId,
  { id: 'VT', label: 'Vermont', value: 'Vermont' } as ISelectItemWithId,
  { id: 'VA', label: 'Virginia', value: 'Virginia' } as ISelectItemWithId,
  { id: 'WA', label: 'Washington', value: 'Washington' } as ISelectItemWithId,
  { id: 'WV', label: 'West Virginia', value: 'West Virginia' } as ISelectItemWithId,
  { id: 'WI', label: 'Wisconsin', value: 'Wisconsin' } as ISelectItemWithId,
  { id: 'WY', label: 'Wyoming', value: 'Wyoming' } as ISelectItemWithId,
] as ISelectItemWithId[];
